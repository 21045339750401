/* eslint-disable no-underscore-dangle */
import { FC, Fragment, memo, useEffect, useState } from 'react';
import GTM from '@utils/gtm';
import ContentWrapper from '@components/ContentWrapper';
import ProductCard from '@components/ProductCard';
import DummyProductCard from '@components/DummyProductCard';
import EditorialCard from '@components/EditorialCard';
import slugify from '@utils/slugify';
import type { DatoProduct, Services } from '@framework/api/types';
import { SubscriptionProductCollection } from 'fragile/types';
import SubscriptionCard from '@components/SubscriptionCard';
import * as styles from './styles';

export type Props = {
  heading: string;
  shortDescription?: string;
  currencyCode: string;
  collectionName: string;
  products?: DatoProduct[];
  // TODO: type this
  productsAndCards?: any;
  subscriptionProducts: SubscriptionProductCollection[];
  subscriptionProductPosition?: number;
  shouldTrack?: boolean;
};

type CombinedCard = {
  id: string;
  data: DatoProduct | SubscriptionProductCollection;
};

function isSubscriptionProduct(
  data: any
): data is SubscriptionProductCollection {
  return data && data.monthlyPrice;
}

const CollectionList: FC<Props> = ({
  heading,
  products,
  subscriptionProducts,
  subscriptionProductPosition,
  shortDescription,
  currencyCode,
  collectionName,
  productsAndCards,
  shouldTrack,
}) => {
  const [hasBeenTrackedInGA, setHasBeenTrackedInGA] = useState(null);

  // track product impressions for GA enhanced ecommerce
  useEffect(() => {
    let timeout = null;

    if (shouldTrack && Array.isArray(products) && products.length > 0) {
      const items = [];

      products.forEach((product, i) => {
        items.push({
          item_name: product.name,
          item_list_name: `${collectionName} Collection Page List`,
          item_list_id: slugify(`${collectionName} Collection Page List`),
          item_id:
            Array.isArray(product.variants) && product.variants.length > 0
              ? (
                  product.variants.find(variant => variant.stock > 0) ||
                  product.variants[0]
                ).sku
              : product.slug,
          price: product.collectionPagePrice
            ? product.collectionPagePrice.toString()
            : null,
          brand: 'NZXT',
          variant:
            Array.isArray(product.variants) &&
            product.variants.length > 0 &&
            Array.isArray(product.variants[0].color)
              ? product.variants[0].color.map(color => color.name).join('/')
              : null,
          position: i,
        });
      });

      if (collectionName !== hasBeenTrackedInGA) {
        timeout = setTimeout(() => {
          GTM.dataLayer({
            dataLayer: {
              event: 'view_item_list',
              ecommerce: {
                item_list_name: `${collectionName} Collection Page List`,
                item_list_id: slugify(`${collectionName} Collection Page List`),
                items,
              },
            },
          });
          setHasBeenTrackedInGA(collectionName);
        }, 1);
      }
    }
    return () => clearTimeout(timeout);
  }, [hasBeenTrackedInGA, products, currencyCode, collectionName, shouldTrack]);

  const isOneRow =
    (products?.length ?? 0) + (subscriptionProducts?.length ?? 0) <= 2;

  const hasProducts = Array.isArray(products) && products?.length > 0;
  const hasProductsAndCards =
    Array.isArray(productsAndCards) && productsAndCards?.length > 0;

  const hasSubscriptionProducts = Boolean(
    Array.isArray(subscriptionProducts) && subscriptionProducts?.length > 0
  );

  const combinedCards: CombinedCard[] = [];

  let sliceIndex = hasProducts ? products.length : 0;

  if (subscriptionProductPosition !== undefined) {
    sliceIndex = Math.max(subscriptionProductPosition - 1, 0);
  }

  if (hasProducts) {
    combinedCards.push(
      ...products.slice(0, sliceIndex).map(product => ({
        id: product.id,
        data: product,
      }))
    );
  }

  if (hasSubscriptionProducts) {
    combinedCards.push(
      ...subscriptionProducts.map(subscription => ({
        id: subscription.fromCms.id,
        data: subscription,
      }))
    );
  }

  if (hasProducts && products.length > sliceIndex) {
    combinedCards.push(
      ...products.slice(sliceIndex).map(product => ({
        id: product.id,
        data: product,
      }))
    );
  }

  const hasMergedProducts = combinedCards.length > 0;

  return hasMergedProducts || hasProductsAndCards ? (
    <ContentWrapper>
      <div className={styles.wrapper} data-test-id="collections-list-block">
        <div className={styles.composedTextColumn(isOneRow)}>
          {(heading || shortDescription) && (
            <div className={styles.textWrapper}>
              <div className={styles.stickyText(isOneRow)}>
                {heading && (
                  <h2
                    className={styles.heading}
                    data-test-id="collections-list-heading"
                  >
                    {heading}
                  </h2>
                )}
                {shortDescription && (
                  <p
                    className={styles.subheading}
                    data-test-id="collections-list-shrt-des"
                  >
                    {shortDescription}
                  </p>
                )}
              </div>
            </div>
          )}
        </div>
        <div className={styles.listWrapper}>
          {hasMergedProducts
            ? combinedCards.map(card => (
                <Fragment key={`card-${card.id}`}>
                  {isSubscriptionProduct(card.data) ? (
                    <div key={card.data.fromCms.id}>
                      <SubscriptionCard
                        key={card.data.fromCms.id}
                        subscriptionProductCollection={card.data}
                        isSmall
                      />
                    </div>
                  ) : (
                    <div key={card.data.id}>
                      <ProductCard
                        key={card.data.id}
                        collectionImage={card.data.collectionPageMainImage}
                        name={card.data.name}
                        slug={card.data.slug}
                        rating={card.data?.variants[0]?.shopify_parent_id}
                        variants={card.data.variants}
                        productPrice={card.data.collectionPagePrice}
                        currencyCode={currencyCode}
                        shortDescription={card.data.shortDescription}
                        availableRegions={card.data.availableRegions}
                        regionalServices={
                          card.data?.regionalServices as Services
                        }
                        isDark={card.data.isPrime}
                        isSmall
                      />
                    </div>
                  )}
                </Fragment>
              ))
            : null}

          {hasProductsAndCards
            ? productsAndCards.map(itm => (
                <Fragment key={itm?.id}>
                  {itm?._modelApiKey === 'product' && (
                    <ProductCard
                      collectionImage={itm?.collectionPageMainImage}
                      name={itm?.name}
                      slug={itm?.slug}
                      rating={itm?.variants[0]?.shopify_parent_id}
                      variants={itm?.variants}
                      productPrice={itm?.collectionPagePrice}
                      currencyCode={currencyCode}
                      shortDescription={itm?.shortDescription}
                      availableRegions={itm?.availableRegions}
                      regionalServices={itm?.regionalServices || []}
                      isDark={itm?.isPrime}
                    />
                  )}
                  {itm?._modelApiKey === 'custom_card' && (
                    <DummyProductCard
                      name={itm?.title}
                      slug={itm?.buttonLink}
                      shortDescription={itm?.shortDescription}
                      isExtendedCard={itm?.isExtendedCard}
                      extendedHeading={itm?.subsectionTitle}
                      extendedDetails={[itm?.subsectionDetails]}
                      ctaTitle={itm?.ctaTitle}
                      collectionImage={itm?.mainAsset}
                      eventType="collectionListClickDummyCard"
                    />
                  )}

                  {itm?._modelApiKey === 'editorial_card' && (
                    <EditorialCard
                      isInverted={itm?.isInverted}
                      contentHorizontalAlignment={
                        itm?.contentHorizontalAlignment
                      }
                      contentVerticalAlignment={itm?.contentVerticalAlignment}
                      eyebrowLogo={itm?.eyebrowLogo}
                      heading={itm?.heading}
                      subheading={itm?.subheading}
                      internalLink={itm?.internalLink}
                      backgroundImage={itm?.backgroundImage}
                      backgroundImageAlignment={itm?.backgroundImageAlignment}
                      backgroundColor={itm?.backgroundColor}
                      gradientColor={itm?.gradientColor}
                      buttonLabel={itm?.buttonLabel}
                      useLeadText={itm?.leadTextStyles}
                      eventType="collectionListClickEditorialCard"
                    />
                  )}
                </Fragment>
              ))
            : null}
        </div>
      </div>
    </ContentWrapper>
  ) : null;
};

export default memo(CollectionList);
