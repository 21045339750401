/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/explicit-function-return-type */

import { Fragment, useMemo } from 'react';
import { useRouter } from 'next/router';
import { Category, DatoCollection, Page } from '@framework/api/types';
import { MAXIFY_CURRENCY_MAP_PER_REGION, ROUTE_ABOUT } from '@constants';
import SectionWrapper from '@components/SectionWrapper';
import MarkdownSection from '@components/Page/MarkdownSection';
import HeroCentered from '@components/HeroCentered';
import HeroTall from '@components/HeroTall';
import CountdownHero from '@components/CountdownHero';
import VideoHero from '@components/VideoHero';
import RiverItem from '@components/RiverItem';
import ContentWrapper from '@components/ContentWrapper';
import Notice from '@components/Page/Notice';
import {
  Hero,
  CollectionList,
  ComparisonTable,
  IconSection,
} from '@components/CollectionPage';
import ProductCollectionGrid from '@components/ProductCollectionGrid';
import PrebuiltComparisonTable from '@components/PrebuiltComparisonTable';
import CollectionCardSection from '@components/CollectionCard';
import MarketingCardSection from '@components/MarketingCards/MarketingCardSection';
import LogoCloud from '@components/LogoCloud';
import HeadingSection from '@components/HeadingSection';
import Testimonials from '@components/Testimonials';
import CtaSection from '@components/Cta/CtaSection';
import ReviewsList from '@components/ReviewsList';
import RelatedItems from '@components/PDP/RelatedItems';
import ContentGrid from '@components/ContentGrid';
import SimpleCardGrid from '@components/SimpleCardGrid';
import { TabGroup } from '@components/Tabs';
import VideoCarousel from '@components/VideoCarousel';
import VideoBlock from '@components/VideoBlock';
import LifeStyleCarousel from '@components/LifeStyleCarousel';
import MediaObject from '@components/MediaObject';
import Embed from '@components/Embed';
import MarkdownIconBlockSection from '@components/MarkdownIconBlockSection';
import SignUpSection from '@components/SignUpSection';
import HelpBlock from '@components/HelpBlock';
import AnimatedZoomSection from '@components/AnimatedZoomSection';
import HeroBlock from '@components/HeroBlock';
import FAQSection from '@components/FAQSection';
import CollectionLineup from 'fragile/components/CollectionLineup/CollectionLineup';
import SingleItemTableSpecs from '@components/CollectionPage/ComparisonTable/Table/SingleItemTableSpecs';
import SectionHeading from '@components/SectionHeading';
import * as styles from './styles';

type Props = {
  data: DatoCollection | Category;
  region: string;
  buildPage?: Page;
};

const PageContent = ({ data, region, buildPage }: Props) => {
  // Currency Code
  const currencyCode = useMemo(
    () => MAXIFY_CURRENCY_MAP_PER_REGION[region],
    [region]
  );
  // TODO: Doing this is not scalable, will need a ticket to update MarkdownSection
  // with a new component to properly display this (SectionWrapper & Content Wrapper alone do not resolve issue)
  const { asPath } = useRouter();
  const limitMarkdownSectionWidth = asPath?.includes(ROUTE_ABOUT);

  // Content for Prebuilt Comparison Section
  const prebuiltCardRegion = region.toLowerCase();
  const prebuildCardKey = `prebuiltCards${
    region.toLowerCase().charAt(0).toUpperCase() + prebuiltCardRegion.slice(1)
  }`;
  const prebuildCardFpsKey = `chipsetType${
    region.toLowerCase().charAt(0).toUpperCase() + prebuiltCardRegion.slice(1)
  }`;

  // Other content checks:
  const hasCollectionGridProducts =
    data?.collectionGridItems &&
    Object.keys(data?.collectionGridItems)?.length > 0;
  const hasLifeStyleProducts =
    data?.featuredLifeStyleItems &&
    Object.keys(data?.featuredLifeStyleItems)?.length > 0;
  const hasProductGridProducts =
    data?.productGridCards && Object.keys(data?.productGridCards)?.length > 0;
  const hasMarketingCollection =
    data?.marketingProducts && Object.keys(data?.marketingProducts)?.length > 0;
  const hasRelatedProducts =
    data?.relatedProductsCards &&
    Object.keys(data?.relatedProductsCards)?.length > 0;
  const hasComparedCards =
    data?.comparedProductCards &&
    Object.keys(data?.comparedProductCards).length > 0;
  const hasCountdownHero =
    data?.countdownHero && Object.keys(data?.countdownHero)?.length > 0;

  const hasCollectionList = data?.content?.some(
    s => s._modelApiKey === 'collection_list'
  );
  const hasCollectionGrid = data?.content?.some(
    s => s._modelApiKey === 'product_collection_grid'
  );
  const hasProductGrid = data?.content?.some(
    s => s._modelApiKey === 'product_grid'
  );

  const hasOverrideProducts =
    data?.comparisonProducts &&
    Array.isArray(data?.comparisonProducts) &&
    data?.comparisonProducts.length > 1;

  let whereToTrackGa = '';

  if (hasCollectionList) {
    whereToTrackGa = 'collection_list';
  }

  if (hasCollectionGrid) {
    whereToTrackGa = 'product_collection_grid';
  }

  if (hasProductGrid) {
    whereToTrackGa = 'product_grid';
  }
  return (
    <>
      {data.content.map(
        (s, i) =>
          s.id && (
            <Fragment key={s.id}>
              {(() => {
                switch (s._modelApiKey) {
                  case 'hero_centered':
                    return <HeroCentered {...s} />;
                  case 'hero_tall':
                    return <HeroTall {...s} />;
                  case 'hero':
                    return (
                      <SectionWrapper>
                        <Hero {...s} />
                      </SectionWrapper>
                    );
                  case 'countdown_hero':
                    return hasCountdownHero ? (
                      <CountdownHero {...data?.countdownHero[s?.id]} />
                    ) : null;
                  case 'video_hero':
                    return <VideoHero {...s} altHeading={data?.name} />;
                  case 'river':
                    return <RiverItem {...s} isEven={i % 2 === 0} />;
                  case 'page_notice_collection':
                    return (
                      <ContentWrapper>
                        <Notice {...s} pageNotice={s.pageNotice} />
                      </ContentWrapper>
                    );
                  case 'collection_list':
                    return (
                      <SectionWrapper>
                        <CollectionList
                          {...s}
                          collectionName={data?.name}
                          products={data?.products}
                          productsAndCards={data?.productsAndCardsMix}
                          subscriptionProducts={data?.fragileProductCollections}
                          subscriptionProductPosition={
                            s?.subscriptionProductPosition
                          }
                          currencyCode={currencyCode}
                          shouldTrack={whereToTrackGa === 'collection_list'}
                        />
                      </SectionWrapper>
                    );
                  case 'product_collection_grid':
                    return hasCollectionGridProducts ? (
                      <ProductCollectionGrid
                        collectionName={data?.name}
                        currencyCode={currencyCode}
                        products={data?.collectionGridItems[s?.id]}
                        eyebrowLogo={s?.eyebrowLogo}
                        heading={s?.heading}
                        subheading={s?.subheading}
                        eyebrow={s?.eyebrow}
                        columnCount={s?.columnCount}
                        blockType="product-collection-grid-block"
                        shouldTrack={
                          whereToTrackGa === 'product_collection_grid'
                        }
                      />
                    ) : null;
                  case 'comparison_table': {
                    return (
                      <SectionWrapper className="overflow-hidden">
                        <ComparisonTable
                          {...s}
                          heading={s.heading}
                          products={
                            hasOverrideProducts
                              ? data?.comparisonProducts
                              : data?.products
                          }
                          currencyCode={currencyCode}
                        />
                      </SectionWrapper>
                    );
                  }
                  case 'collection_comparison_table': {
                    return (
                      <SectionWrapper className="overflow-hidden">
                        <ComparisonTable
                          heading={s.heading}
                          products={data?.collectionComparison}
                          currencyCode={currencyCode}
                        />
                      </SectionWrapper>
                    );
                  }
                  case 'prebuilt_comparison_section':
                    return hasComparedCards && s[prebuildCardKey] ? (
                      <PrebuiltComparisonTable
                        prebuiltCards={s[prebuildCardKey]}
                        comparisonProducts={
                          hasComparedCards && data?.comparedProductCards[s?.id]
                        }
                        heading={s.heading}
                        subheading={s.subheading}
                        enabledCardFields={s.enabledCardFields}
                        chipsetType={s[prebuildCardFpsKey]}
                      />
                    ) : null;
                  case 'icon_block':
                    return (
                      <SectionWrapper>
                        <IconSection {...s} hasBg data={s.iconBlock} />
                      </SectionWrapper>
                    );
                  case 'logo_cloud':
                    return <LogoCloud {...s} />;
                  case 'marketing_product_card':
                    return hasMarketingCollection ? (
                      <section className={styles.spacingBottom}>
                        <CollectionCardSection
                          collectionData={data?.marketingProducts[s?.id]}
                        />
                      </section>
                    ) : null;
                  case 'marketing_card2_x2_collection':
                    return (
                      <section
                        className={styles.spacingBottom}
                        data-test-id="marketing-card2-x2-block"
                      >
                        <MarketingCardSection
                          {...s}
                          marketingCardData={s.marketingCard}
                        />
                      </section>
                    );
                  case 'section_heading':
                    return <HeadingSection {...s} />;
                  case 'testimonal_collection':
                    return <Testimonials testimonialsData={s.testimonials} />;
                  case 'cta_block':
                    return (
                      <SectionWrapper>
                        <CtaSection ctaData={s.cta} />
                      </SectionWrapper>
                    );
                  case 'product_review':
                    return <ReviewsList reviews={s.reviews} />;
                  case 'related_product':
                    return hasRelatedProducts ? (
                      <RelatedItems
                        {...s}
                        products={data?.relatedProductsCards[s?.id]}
                        currencyCode={currencyCode}
                      />
                    ) : null;
                  case 'content_grid':
                    return <ContentGrid {...s} />;
                  case 'simple_card_grid':
                    return (
                      <SimpleCardGrid
                        simpleCardGridData={s?.simpleCards}
                        heading={s?.sectionHeading}
                        {...s}
                      />
                    );
                  case 'product_grid':
                    return hasProductGridProducts ? (
                      <ProductCollectionGrid
                        currencyCode={currencyCode}
                        products={data?.productGridCards[s?.id]}
                        eyebrowLogo={s?.eyebrowLogo}
                        heading={s?.heading}
                        subheading={s?.subheading}
                        eyebrow={s?.eyebrow}
                        columnCount={s?.columnCount}
                        collectionName={data?.name}
                        isExtendedCard={s?.isExtended}
                        backgroundColor={s?.backgroundColor?.hex}
                        textPosition={s?.textPosition}
                        blockType="product-grid-block"
                        shouldTrack={whereToTrackGa === 'product_grid'}
                      />
                    ) : null;
                  case 'tab':
                    return (
                      <SectionWrapper>
                        <TabGroup {...s} tabs={s?.tab} />
                      </SectionWrapper>
                    );
                  case 'video_carousel':
                    return <VideoCarousel {...s} />;
                  case 'video_block':
                    return (
                      <VideoBlock
                        heading={s.heading}
                        eyebrow={s.eyebrow}
                        subheading={s.subheading}
                        video={s.video}
                        videoPoster={s.videoPoster}
                        buttonLabel={s.buttonLabel}
                        buttonLink={s.internalButtonLink}
                        isFullBleed={s.isFullbleed}
                        variableSpacing={s.variableSpacing}
                        darkText={s.darkText}
                      />
                    );
                  case 'life_style_carousel':
                    return (
                      <LifeStyleCarousel
                        {...s}
                        currencyCode={currencyCode}
                        featuredProducts={
                          hasLifeStyleProducts &&
                          data?.featuredLifeStyleItems[s?.id]
                        }
                      />
                    );
                  case 'media_object_section':
                    return <MediaObject {...s} />;
                  case 'embed_module':
                    return (
                      <SectionWrapper>
                        <ContentWrapper>
                          <Embed url={s.embedUrl} heading={s.heading} {...s} />
                        </ContentWrapper>
                      </SectionWrapper>
                    );
                  case 'markdown_icon_section':
                    return (
                      <MarkdownIconBlockSection
                        markdownIconBlocks={s.markdownIconBlocks}
                      />
                    );
                  case 'email_sign_up_section':
                    return <SignUpSection {...s} />;
                  case 'help_block':
                    return <HelpBlock {...s} />;
                  case 'image_scroll_jack':
                    return <AnimatedZoomSection {...s} />;
                  // Markdown Content
                  case 'markdown_content':
                    return (
                      <MarkdownSection
                        {...s}
                        applyWidthLimit={limitMarkdownSectionWidth}
                      />
                    );

                  // Enhanced Hero
                  case 'hero_block':
                    return <HeroBlock buildPage={buildPage} {...s?.hero} />;

                  // FAQ Section
                  case 'faq_section':
                    return <FAQSection {...s} />;

                  case 'collection_lineup':
                    return (
                      <CollectionLineup
                        subscriptionProductCollections={
                          data?.fragileProductCollections
                        }
                        heading={s.heading}
                      />
                    );

                  case 'detail_table':
                    return (
                      <ContentWrapper>
                        <SectionWrapper>
                          <SectionHeading
                            heading={s?.heading}
                            subheading={s?.subheading}
                            textPosition="left"
                          />
                          <SingleItemTableSpecs
                            decoratedSpecs={[
                              ...Object.keys(s?.details ?? {}).map(
                                specName => ({
                                  label: specName,
                                  value: s?.details[specName],
                                })
                              ),
                              {
                                label: 'More Info',
                                value: 'View Agreement',
                                link: s?.moreInfo,
                              },
                            ]}
                          />
                        </SectionWrapper>
                      </ContentWrapper>
                    );

                  default:
                    return '';
                }
              })()}
            </Fragment>
          )
      )}
    </>
  );
};

export default PageContent;
